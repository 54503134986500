import { initializeApp } from "firebase/app";
import { getAuth, signOut , signInWithEmailAndPassword, createUserWithEmailAndPassword, updatePassword, setPersistence, browserLocalPersistence} from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyAcxLoQ3ifBVfkwcfHsVjmWH4VbZd5SpzQ",
  authDomain: "dev-tobs.firebaseapp.com",
  projectId: "dev-tobs",
  storageBucket: "dev-tobs.firebasestorage.app",
  messagingSenderId: "599839750507",
  appId: "1:599839750507:web:686292449b4653b7e6342e"
};



const createUser = async (email, password) => {
  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;  // propagate the error up to the caller
  }
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// Initialize Firestore
const db = getFirestore(app);

// Enable better security settings
setPersistence(auth, browserLocalPersistence);

// Enable offline persistence with security settings
enableIndexedDbPersistence(db, {
  synchronizeTabs: true,
}).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
  } else if (err.code === 'unimplemented') {
    console.warn('The current browser does not support persistence.');
  }
});

export { auth, db, signOut, signInWithEmailAndPassword, createUser, updatePassword };

