import React, { useState, useEffect, useMemo } from "react";
import ExcelJS from 'exceljs';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import sort from "./../images/sort.png";
import firebase, {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import "./../App.css";
import { auth, db } from "./../firebaseConfig";
import Select, { components } from "react-select";
import { customStyles } from "./../helpers/StyleUtils";
function ExcelUploadButton() {
  const [file, setFile] = useState(null);
  const [potentialOrders, setPotentialOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    Studio: "",
    SetDesc: "",
    // Initialize other filters as needed
  });

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: "16px", margin: "0" }}>
            <svg
              version="1.1"
              id="Isolationsmodus"
              x="0px"
              y="0px"
              viewBox="0 0 322.9 257.78"
              shapeRendering="geometricPrecision"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z"
              />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };
  // Handle checkbox change remains the same

  // Sorting function
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    sortArray(key, direction);
  };

  // Function to sort the array
  const sortArray = (key, direction = "ascending") => {
    setPotentialOrders(
      [...potentialOrders].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      })
    );
  };

  const handleCheckboxChange = (index) => {
    setSelectedOrders((prevSelected) => ({
      ...prevSelected,
      [index]: !prevSelected[index],
    }));
  };

  function reformatDate(dateStr) {
    // Check if the date is in the format 'd.m.yyyy' or 'dd.mm.yyyy'
    const regex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

    if (regex.test(dateStr)) {
      // Split the date, pad each part with leading zero if necessary, and join with slashes
      return dateStr
        .split(".")
        .map((part) => part.padStart(2, "0"))
        .join("/");
    } else {
      // If the date is not in the expected format, return it as is
      return dateStr;
    }
  }

  function normalizeTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const normalizedHours = Math.floor(totalMinutes / 60);
    const normalizedMinutes = totalMinutes % 60;
    return (
      normalizedHours.toString().padStart(2, "0") +
      ":" +
      normalizedMinutes.toString().padStart(2, "0")
    );
  }

  useEffect(() => {
    console.log("Loading state is now:", isloading);
  }, [isloading]);
  const toggleSelectAll = () => {
    let newSelected = {};
    if (!selectAll) {
      potentialOrders.forEach((_, index) => {
        newSelected[index] = true;
      });
    }
    setSelectedOrders(newSelected);
    setSelectAll(!selectAll);
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      await scanExcel(file);
    }
  };

  const scanExcel = async (selectedFile) => {
    console.log("File selected:", selectedFile);
    setIsLoading(true);

    try {
      // Convert File object to ArrayBuffer
      const arrayBuffer = await selectedFile.arrayBuffer();
      
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
      const firstWorksheet = workbook.getWorksheet(2); // Get second worksheet

      if (!firstWorksheet) {
        throw new Error("Worksheet not found");
      }

      const columnMapping = {
        A: "Studio",
        B: "Set",
        C: "Datum",
        D: "Kundenshootings",
        E: "Start",
        F: "Ende",
        G: "KPI Plan",
        H: "Foto",
        I: "Digi-OP",
        J: "Styling Assist",
        K: "Video",
        L: "Styling",
        M: "Hair & Make-Up",
        N: "Art Direktion",
        O: "PRODUCER",
        P: "Sonstige Info",
        Q: "Location",
        R: "Model",
        S: "Model",
        T: "Id",
      };

      const relevantColumns = ["H", "I", "J", "K", "L", "M", "N", "O"];
      let extractedOrders = [];

      // Process each row
      firstWorksheet.eachRow((row, rowNumber) => {
        const rowData = {};
        
        // Convert row values to an object with column letters as keys
        row.eachCell((cell, colNumber) => {
          const colLetter = String.fromCharCode(64 + colNumber);
          
          // Handle different cell types
          let cellValue;
          if (cell.type === ExcelJS.ValueType.Date) {
            cellValue = cell.value; // ExcelJS automatically converts dates
          } else if (cell.type === ExcelJS.ValueType.Number) {
            cellValue = cell.value;
          } else {
            cellValue = cell.text || cell.value;
          }
          
          rowData[colLetter] = cellValue;
        });

        // Check relevant columns for "anfrage"
        relevantColumns.forEach(col => {
          if (
            rowData[col] &&
            String(rowData[col]).toLowerCase().includes("anfrage".toLowerCase())
          ) {
            let setModel;
            if (rowData["B"] && String(rowData["B"]).toLowerCase() === "model".toLowerCase()) {
              setModel = { value: "onmodel", label: "On Model" };
            } else if (rowData["B"]) {
              setModel = { value: "Produkt", label: "On Produkt" };
            } else {
              setModel = { value: "", label: "" };
            }

            // Convert Excel date to JavaScript date
            const datumValue = rowData["C"];
            let datumDate;
            if (datumValue instanceof Date) {
              datumDate = datumValue;
            } else if (typeof datumValue === 'number') {
              datumDate = excelSerialDateToDate(datumValue);
            } else {
              datumDate = new Date(); // fallback
            }

            const orderData = {
              Studio: rowData["A"] || "",
              SetDesc: rowData["B"] || "",
              Set: setModel,
              Id: rowData["T"] || "",
              Datum: datumDate.toLocaleDateString(),
              Start: typeof rowData["E"] === 'number' ? excelFractionToTime(rowData["E"]) : rowData["E"],
              Ende: typeof rowData["F"] === 'number' ? excelFractionToTime(rowData["F"]) : rowData["F"],
              Kundenshootings: rowData["D"] || "",
              DatumEnd: datumDate.toLocaleDateString(),
              KPIPlan: typeof rowData["G"] === 'number' ? Math.round(rowData["G"]) : 0,
              position: columnMapping[col],
              SonstigeInfo: rowData["P"] || "",
              Location: rowData["Q"] || "",
              Model: rowData["R"] || "",
            };

            console.log("Extracted order:", orderData); // Debug log
            extractedOrders.push(orderData);
          }
        });
      });

      console.log("Total extracted orders:", extractedOrders.length); // Debug log

      // Group orders by Id and position
      let mergedOrders = {};
      extractedOrders.forEach((order) => {
        const key = order.Id + "/" + order.position;
        if (!mergedOrders[key]) {
          mergedOrders[key] = { ...order, count: 1 };
        } else {
          mergedOrders[key].DatumEnd = order.Datum;
          mergedOrders[key].count += 1;
        }
      });

      const finalOrders = Object.values(mergedOrders).map((order) => ({
        ...order,
        NumDays: order.count,
      }));

      console.log("Final orders:", finalOrders); // Debug log
      setPotentialOrders(finalOrders);
      setIsLoading(false);

    } catch (error) {
      console.error("Error processing Excel file:", error);
      setIsLoading(false);
      alert(`Error processing Excel file: ${error.message}`);
    }

    // Firebase storage upload code...
  };

  // Helper function for Excel date conversion
  const excelSerialDateToDate = (serial) => {
    const utc_days  = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;  
    const date_info = new Date(utc_value * 1000);
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
  };

  // Helper function for Excel time conversion
  const excelFractionToTime = (fraction) => {
    if (!fraction) return '';
    
    const totalMinutes = Math.round(fraction * 24 * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  function parseDate(dateString) {
    const parts = dateString.split("/");
    // Note: months are 0-based in JavaScript Date, so subtract 1
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  function parseISODate(dateString) {
    return new Date(dateString);
  }

  function toISOFormat(dateStr) {
    // Assuming dateStr is in 'dd/mm/yyyy' format
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to 'yyyy-mm-dd'
    }
    return dateStr; // Return the original string if it doesn't match the expected format
  }

  const createSelectedOrders = async () => {
    const firestore = getFirestore();
    const ordersCollection = collection(
      firestore,
      "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
    );
    const companyCollection = collection(
      firestore,
      "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"
    );

    for (let index in selectedOrders) {
      if (selectedOrders[index]) {
        const order = potentialOrders[index];

        // Extract or determine the values for the new fields
        const isoStartDate = toISOFormat(reformatDate(order.Datum));
        const startDate = parseISODate(isoStartDate);

        const isoEndDate = toISOFormat(reformatDate(order.DatumEnd));
        const endDate = parseISODate(isoEndDate);

        // ... (similarly extract other values as needed)
        //console.log('this is a date '+ startDate) ;
        const newOrderData = {
          start_date: Timestamp.fromDate(new Date(startDate)),
          end_date: Timestamp.fromDate(new Date(startDate)),

          title: "", // You need to determine the value for this
          kpi: order.KPIPlan, // Assuming KPIPlan is the kpi
          setDesc: order.SetDesc,
          set: order.Set.label,
          position: { value: order.position, label: order.position },
          startTime: normalizeTime(order.Start),
          endTime: normalizeTime(order.Ende),
          client: {
            value: order.Kundenshootings, // The unique identifier for the client
            label: order.Kundenshootings, // The human-readable name of the client
          }, // You need to determine the value for this
          segment: "", // You need to determine the value for this
          range: "", // You need to determine the value for this
          costcenter1: " ",
          costcenter2: " ",
          option: "option", // You need to determine the value for this
          status: "new order",
          location: order.Location,
          studio: order.Studio,
          createdBy: localStorage.getItem("userEmail"),
          createdAt: Timestamp.fromDate(new Date()),
          orderId: order.Id,
          SonstigeInfo:order.SonstigeInfo,
        };

        const clientQuery = query(
          companyCollection,
          where("name", "==", order.Kundenshootings)
        );
        const clientSnapshot = await getDocs(clientQuery);

        // If the client does not exist, add them to the company collection
        if (clientSnapshot.empty) {
          await addDoc(companyCollection, {
            name: order.Kundenshootings,
            // ... any other client details you want to add ...
          });
        }

        await addDoc(ordersCollection, newOrderData);
        await addDoc(
          collection(
            db,
            "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"
          ),
          {
            userId: "superadmin",
            message: `new order ${newOrderData.client.label}`,
            read: false,
            bookingId: "",
            url: "admin/allnewbookings",
            createdAt: Timestamp.now(),
          }
        );
        await addDoc(
          collection(
            db,
            "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"
          ),
          {
            userId: "setCastEditor",
            message: `new order ${newOrderData.client.label}`,
            read: false,
            bookingId: "",
            url: "admin/allnewbookings",
            createdAt: Timestamp.now(),
          }
        );
      }
    }
    alert("Selected orders have been created!");
    if (localStorage.getItem("userRole") === "planning") {
      console.log("this is planing user role go so paning");
      window.location.href = "/cs/allgroups";
    } else {
      window.location.href = "/admin/allgroups";
    }
  };
  // Add the filter handling function
  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  // Example of how to apply filters to the potential orders list
  const filteredOrders = useMemo(() => {
    return potentialOrders.filter((order) => {
      return (
        (!filters.Studio || order.Studio === filters.Studio) &&
        (!filters.SetDesc || order.SetDesc === filters.SetDesc) &&
        (!filters.Kundenshootings ||
          order.Kundenshootings === filters.Kundenshootings)
      );
      // Add other filter conditions as necessary
    });
  }, [potentialOrders, filters]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const uniqueStudioOptions = useMemo(() => {
    const allStudios = potentialOrders.map((order) => order.Studio);
    return Array.from(new Set(allStudios)).sort();
  }, [potentialOrders]);

  const uniqueSetDescOptions = useMemo(() => {
    const allSetDescs = potentialOrders.map((order) => order.SetDesc);
    return Array.from(new Set(allSetDescs)).sort();
  }, [potentialOrders]);
  const uniqueKundednOptions = useMemo(() => {
    const allSetDescs = potentialOrders.map((order) => order.Kundenshootings);
    return Array.from(new Set(allSetDescs)).sort();
  }, [potentialOrders]);

  const setDescOptions = uniqueSetDescOptions.map((setDesc) => ({
    value: setDesc,
    label: setDesc,
  }));
  const setStudio = uniqueStudioOptions.map((setDesc) => ({
    value: setDesc,
    label: setDesc,
  }));
  const setKunden = uniqueKundednOptions.map((setDesc) => ({
    value: setDesc,
    label: setDesc,
  }));

  return (
    <section>
      <div className="content-wrapper">
        <div>
          {potentialOrders.length === 0 ? (
            // If no orders have been processed, show the "Choose File" button
            <div>
              <label htmlFor="fileInput" className="big-button">
                Choose File
              </label>
              <input
                type="file"
                id="fileInput"
                accept=".xls,.xlsx"
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </div>
          ) : isloading ? (
            // If isLoading is true, show the spinner
            <div class="spinner"></div>
          ) : (
            // If there are potential orders and is not loading, show the orders list
            <>
              <h1>Potential Orders</h1>
              <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                <div style={{ display: "flex" }}>
                  <button
                    className="button"
                    style={{ width: "200px", margin: "5px" }}
                    onClick={toggleSelectAll}
                  >
                    {selectAll ? "Unselect All" : "Select All"}
                  </button>
                  <button
                    className="button"
                    style={{ width: "200px", margin: "5px" }}
                    onClick={createSelectedOrders}
                  >
                    Create selected orders
                  </button>
                </div>
              </div>
              <div
                style={{
                  marginTop: "0px",
                  marginBottom: "60px",
                  display: "flex",
                }}
              >
                <Select
                  value={
                    filters.SetDesc
                      ? setDescOptions.find(
                          (option) => option.value === filters.SetDesc
                        )
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleFilterChange(
                      "SetDesc",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  options={setDescOptions}
                  isClearable
                  placeholder="Filter by Set"
                  components={{ DropdownIndicator }}
                  styles={customStyles}
                />
                <Select
                  value={
                    filters.Studio
                      ? setDescOptions.find(
                          (option) => option.value === filters.Studio
                        )
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleFilterChange(
                      "Studio",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  options={setStudio}
                  isClearable
                  placeholder="Filter by Studio"
                  components={{ DropdownIndicator }}
                  styles={customStyles}
                />
                <Select
                  value={
                    filters.Kundenshootings
                      ? setDescOptions.find(
                          (option) => option.value === filters.Kundenshootings
                        )
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleFilterChange(
                      "Kundenshootings",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  options={setKunden}
                  isClearable
                  placeholder="Filter by Client"
                  components={{ DropdownIndicator }}
                  styles={customStyles}
                />
              </div>
              <div className="list-tasks-header">
                <div style={{ flexBasis: "30px" }}>
                  <img src={sort} height="30px" />
                </div>
                <div
                  style={{ flexBasis: "100px" }}
                  onClick={() => requestSort("Studio")}
                >
                  Studio{" "}
                </div>
                <div
                  style={{ flexBasis: "80px" }}
                  onClick={() => requestSort("SetDesc")}
                >
                  Set
                </div>
                <div
                  style={{ flexBasis: "120px" }}
                  onClick={() => requestSort("Datum")}
                >
                  Datum
                </div>
                <div
                  style={{ flexBasis: "200px" }}
                  onClick={() => requestSort("Kundenshootings")}
                >
                  Kunden
                </div>
                <div
                  style={{ flexBasis: "50px" }}
                  onClick={() => requestSort("KPIPlan")}
                >
                  {" "}
                  KPI{" "}
                </div>
                <div
                  style={{ flexBasis: "100px" }}
                  onClick={() => requestSort("position")}
                >
                  Position
                </div>
                <div
                  style={{ flexBasis: "100px" }}
                  onClick={() => requestSort("SonstigeInfo")}
                >
                  S.Info
                </div>
                <div
                  style={{ flexBasis: "150px" }}
                  onClick={() => requestSort("Location")}
                >
                  Location
                </div>
                <div
                  style={{ flexBasis: "300px" }}
                  onClick={() => requestSort("Id")}
                >
                  Id
                </div>
              </div>
              <ul>
                {filteredOrders.map((order, index) => {
                  const hasError =
                    !order.Id || !order.Kundenshootings || !order.Datum  || !order.SetDesc;
                  const itsOptional = order.SonstigeInfo === "Optional";
                  return (
                    <li
                      key={index}
                      className={`list-tasks-item ${
                        hasError ? "error-row" : ""
                      } ${itsOptional ? "optional" : ""}`}
                    >
                      <div
                        style={{ flexBasis: "30px" }}
                        className="custom-checkbox"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox-${index}`}
                          checked={!!selectedOrders[index]}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label htmlFor={`checkbox-${index}`}></label>
                      </div>

                      <div style={{ flexBasis: "100px" }}>{order.Studio}</div>
                      <div style={{ flexBasis: "80px" }}>{order.SetDesc}</div>
                      <div style={{ flexBasis: "120px" }}>
                        {" "}
                        {reformatDate(order.Datum)} <br />
                        {normalizeTime(order.Start)}-{normalizeTime(order.Ende)}
                      </div>
                      <div style={{ flexBasis: "200px" }}>
                        {" "}
                        {order.Kundenshootings}
                      </div>
                      <div style={{ flexBasis: "50px" }}>{order.KPIPlan}</div>
                      <div style={{ flexBasis: "100px" }}>{order.position}</div>
                      <div style={{ flexBasis: "100px" }}>
                        {order.SonstigeInfo}
                      </div>
                      <div style={{ flexBasis: "150px" }}>{order.Location}</div>
                      <div style={{ flexBasis: "300px" }}>{order.Id}</div>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default ExcelUploadButton;