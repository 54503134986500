import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { db } from './../../../firebaseConfig';


function MyInvitations({ authToken }) {
  const [talentIds, setTalentIds] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [groupedBookings, setGroupedBookings] = useState({});
  const [expandedBookings, setExpandedBookings] = useState({});

  useEffect(() => {
    const fetchUserTalents = async () => {
      const userDoc = await getDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", localStorage.getItem("userId")));
      const userRole = userDoc.data().role; // assuming role is stored as "role" in UserData
      const fetchedTalentIds = userRole === 'superadmin' ? [] : userDoc.data().talentIds; // if superadmin, set to empty array
      setTalentIds(fetchedTalentIds);
    
      const talentDocs = await getDocs(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"));
      let talents = talentDocs.docs.map(doc => ({
        id: doc.id,
        name: doc.data().Name,
        email: doc.data().E_Mail,
        rate: doc.data().Tagessatz,
        lanoid: doc.data().Lano_ID,
        Funktion: doc.data().Funktion
      }))
      .filter(talent => userRole === 'superadmin' ? true : fetchedTalentIds.includes(talent.id)); // if superadmin, include all talents
    
      // Sort talents alphabetically by name
      talents = talents.sort((a, b) => a.name.localeCompare(b.name));
    
      setTalentData(talents);
    
      // Fetch bookings after setting talents
      fetchBookingsForTalents(userRole === 'superadmin' ? talents.map(talent => talent.id) : fetchedTalentIds);
    };
    


    const fetchBookingsForTalents = async (ids) => {
      const allBookings = [];

      for (const id of ids) {
        const bookingsQuery = query(
          collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
          where("talentId", "==", id),
          where("status", "==", "requested")
        );

        const bookingsSnapshot = await getDocs(bookingsQuery);
        let fetchedBookings = bookingsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        // Fetch all company names concurrently
        const companyNames = await Promise.all(fetchedBookings.map(booking => fetchCompany(booking.companyID)));

        // Assign the company name to each booking
        fetchedBookings.forEach((booking, index) => {
          booking.companyName = companyNames[index];
        });

        allBookings.push(...fetchedBookings);
      }

      setBookings(allBookings);
      setGroupedBookings(groupBookings(allBookings));
    }

    fetchUserTalents();

  }, []);


  const fetchCompany = async (companyID) => {
    if (!companyID) {
      console.error("Undefined companyID found!");
      return 'Unknown Company';
    }
    const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID);
    const company = await getDoc(companyDoc);

    if (company.exists) {
      return company.data().name; // assuming the talent document has a 'name' field
    } else {
      return 'deleted company';
    }
  }


  function getWeekNumber(d) {
    // Copy date so don't modify the original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of the year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

    return weekNo;
  }

  const handleBookingStatusChange = async (filteredBooking, bookingId, talent, option, action) => {
    try {
      // Determine the status based on action and option
      let status;
      if (action === 'accept') {
        if(filteredBooking.isdummy){
          status= 'talentOnHold'
        } else {   status = option === 'booking' ? 'confirmed' : 'optioned';}
     
      } else if (action === 'reject') {
        status = 'declined request';
         // Create a copy of the rejected booking with status "new order"
      const newBooking = {
        ...filteredBooking,
        status: 'new order',
        talentId:'',
        group:null,
        groupColor:'',
        statusUpdatedAt: Timestamp.now(),
      };
      delete newBooking.id; // Remove the id property since Firestore will automatically generate a new one
      
      await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"), newBooking);

      console.log("New booking created with status 'new order'");
      }

      // Find all bookings that belong to the same group
      const bookingsInSameGroup = bookings.filter(b => b.avoidGrup === filteredBooking.group);// change to avoidGrup

      // Update all bookings that belong to the same group
   //   for (const booking of bookingsInSameGroup) {
        const bookingDocRef = doc(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);



        await updateDoc(bookingDocRef, { status: status, statusUpdatedAt: Timestamp.now(), });
  
   
   //   }
      

      // Update the local state for bookings
      const updatedBookings = bookings.map(b => bookingsInSameGroup.includes(b) ? { ...b, status: status } : b);

      // Handle email and tasks based on the updated status
      if (status === 'optioned') {
        await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
        {
          userId: filteredBooking.proposedBy,
          message: `${talent.name}  has accepted first option for the order ${filteredBooking.client.label}`,
          read: false,
          bookingId: bookingId,
          url:'admin/detail?id='+bookingId,
          createdAt: Timestamp.now()
        }
    
      )
      } else if (status === 'declined request') {
        await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
        {
          userId: filteredBooking.createdBy,
          message: `Talent ${talent.Name} rejected the order for ${filteredBooking.client.label}. Please propose another talent`,
          read: false,
          bookingId: bookingId,
          url:'cs/allbooking',
          createdAt: Timestamp.now()
        })
        await updateDoc(bookingDocRef, { status: 'deleted',    group:null,
        groupColor:'', statusUpdatedAt: Timestamp.now(), SystemNote: status });
      }

      setBookings(updatedBookings);

      alert('Updated successfully!');
      window.location.reload();

      if (status === 'confirmed') {
        createmailcomfirmed(filteredBooking, talent);
        // Uncomment below if you want to create tasks for confirmed bookings
        // createTask(filteredBooking, talent);
      }
    


    } catch (error) {
      console.error("Error updating booking status:", error);
    }
  };

  const createlanotitle = async (booking, talent) => {
    const lanotitle = 'KW' + getWeekNumber(new Date(booking.start_date.seconds * 1000)) + " ";
  }



  const createmailcomfirmed = async (filteredBooking, talent, start) => {

    const msg = {
      to: ' talents@set-cast.com, talent@mondula.com, ' + localStorage.getItem("userEmail"),
      message: {
        subject: 'Talent has accepted the invitation',
        text: `You have beed proposed: ${JSON.stringify(filteredBooking)}`,
        html: `
      Dear  Team,
      
      
      <p>Talent ${talent.name} has accepted the invitation and the Order has beed confirmed </p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
      },
    };

    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };

  const createmailrejected = async (filteredBooking, talent) => {


    const msg = {
      to: ' talents@set-cast.com, talent@mondula.com,' + localStorage.getItem("userEmail"),
      message: {
        subject: 'Talent has rejected the invitation',
        text: `You have beed proposed: ${JSON.stringify(filteredBooking)}`,
        html: `
      Dear  Team,
      
      
      <p>Talent ${talent.name} has Rejected the invitation </p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>


      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
      },
    };

    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };

  const createmail = async (filteredBooking, talent) => {

    const msg = {
      to: ' talents@set-cast.com, talent@mondula.com, ' + localStorage.getItem("userEmail"),
      message: {
        subject: 'Talent has accepted the invitation',
        text: `You have beed proposed: ${JSON.stringify(filteredBooking)}`,
        html: `
      Dear  Team,
      
      
      <p>Talent ${talent.name} has accepted the invitation </p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>


      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
      },
    };


    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };

  const createTask = async (booking, talent) => {
    console.log('create called' + authToken)
    // add entity - POST
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + authToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Origin', 'http://dev-setcast.mondula.com/');
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
    const kw = 'KW' + getWeekNumber(new Date(booking.start_date.seconds * 1000)) + " ";
    var titile = kw + '_' + booking.client.label + '_' + booking.position.label + '_' + new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0] + '-' + new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0];

    // Replace hardcoded data with the booking details
    var raw = JSON.stringify({
      "task": {
        "title": titile,
        "description": booking.description || '',
        "details": booking.description || '',
        "starts_on": new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0],
        "ends_on": new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0],
        "rate": talent.rate,
        "duration_in_time_units": booking.range,
        "currency": "EUR",

        "budget_setting": "set_budget",
        "assignee_id": talent.lanoid,
        "rate_type_id": 1,
        "creator_id": 216149,
        "state": "freelancer_assigned",
        "task_cost_centers_attributes": [
          {
            "cost_center_1_id": booking.costcenter1.id,
            "cost_center_2_id": booking.costcenter2.id
          }
        ]
      }
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'manual'
    };

    fetch("https://api.lano.io/api/v1/tasks", requestOptions)
      .then(response => response.text())
      .then(result => { console.log(result); /* setMeddage(result); */ }) // Typo fix: it should probably be setMessage
      .catch(error => console.log('error', error));
  }
  const isBookingFirstOfItsGroup = (booking, bookingsList) => {
    const similarBookings = bookingsList.filter(b => b.group === booking.group);
    return similarBookings[0].id === booking.id;
  }


  const groupBookings = (bookingsList) => {
    const grouped = {};
  
    bookingsList.forEach((booking) => {
      // Use booking group if available, otherwise use the booking id
      const groupId = booking.avoidGroup || booking.id; //Change the group word to avoid goruping Anke last asked
  
      if (!grouped[groupId]) {
        grouped[groupId] = [];
      }
      grouped[groupId].push(booking);
    });
  
    Object.keys(grouped).forEach((groupId) => {
      const sortedBookings = grouped[groupId].sort((a, b) => a.start_date.seconds - b.start_date.seconds);
      sortedBookings[sortedBookings.length - 1].isLast = true;
    });
  
    return grouped;
  };
  

  const toggleBookingExpansion = (bookingId) => {
    setExpandedBookings(prev => ({
      ...prev,
      [bookingId]: !prev[bookingId]
    }));
  };

  return (
    <section>
      <div className="content-wrapper">
        <h1>Talent requests</h1>
      </div>
      <div>
        {talentData.map(talent => (
          <div key={talent.id}>
            <div className="content-wrapper">
              <h2 style={{ marginTop:'70px', marginBottom: '50px' }}>{talent.name}- {talent.Funktion}</h2>
            </div>
 
            <div className='content-wrapper content-wrapper-talent-list'>
              {Object.entries(groupedBookings).map(([groupId, groupedBooking]) => {
                const bookingsForTalent = groupedBooking.filter(booking => booking.talentId === talent.id);
                
                return bookingsForTalent.map((filteredBooking) => (
                  <div 
                    className='list-tasks-item-container'
                    key={filteredBooking.id}
                  >
                    <div 
                      className='tlist-preview-row'
                      onClick={() => toggleBookingExpansion(filteredBooking.id)}
                      style={{ 
                    //    borderLeft: `4px solid ${getStatusColor(filteredBooking.status)}`,
                        //backgroundColor: filteredBooking.status === 'requested' ? '#fff8ec' : '#fff'
                      }}
                    >
                      <div className='tlist-preview-date'>
                        {new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}
                      </div>
                      <div className='tlist-preview-client'>
                        {filteredBooking.client.label}
                      </div>
                      <div className='tlist-preview-arrow'>
                        {expandedBookings[filteredBooking.id] ? '↑' : '↓'}
                      </div>
                    </div>

                    {expandedBookings[filteredBooking.id] && (
                      <div className='tlist-expanded-content'>
                        <div className='tlist-detail-row'>
                          <div className='tlist-detail-heading'>Status</div>
                          <div>{filteredBooking.status}</div>
                        </div>
                        <div className='tlist-detail-row'>
                          <div className='tlist-detail-heading'>Start-End</div>
                          <div>{filteredBooking.startTime}- {filteredBooking.endTime}</div>
                        </div>
                        <div className='tlist-detail-row'>
                          <div className='tlist-detail-heading'>KPI</div>
                          <div>{filteredBooking.kpi}</div>
                        </div>
                        <div className='tlist-detail-row'>
                          <div className='tlist-detail-heading'>Set</div>
                          <div>{filteredBooking.set}</div>
                        </div>
                        <div className='tlist-detail-row'>
                          <div className='tlist-detail-heading'>Rate</div>
                          <div>{talent.rate}</div>
                        </div>
                        <div className='tlist-detail-row'>
                          <div className='tlist-detail-heading'>Option</div>
                          <div>{filteredBooking.option}</div>
                        </div>

                        {filteredBooking.isLast && (
                          <div className='tlist-actions'>
                            {filteredBooking.option === 'option'
                              ? <button className='button' onClick={() => handleBookingStatusChange(filteredBooking, filteredBooking.id, talent, filteredBooking.option, 'accept')}>Accept First Option</button>
                              : <button className='button' onClick={() => handleBookingStatusChange(filteredBooking, filteredBooking.id, talent, filteredBooking.option, 'accept')}>Accept Confirmation</button>
                            }
                            <button className='button' onClick={() => handleBookingStatusChange(filteredBooking, filteredBooking.id, talent, filteredBooking.option, 'reject')}>Deny</button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ));
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default MyInvitations;
