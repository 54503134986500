import React, { useState, useEffect } from 'react';
import '../../../App.css';

import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { db } from './../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { ta } from 'date-fns/locale';

const getStatusColor = (status) => {
  switch (status) {
    case 'confirmed':
      return '#15a326';
    case 'new order':
      return '#ffffff';
    case 'requested':
      return '#FF8806';
    case 'talentaccepted':
      return '#3de651';
    case 'declined request':
      return '#ff3c00';
    case 'optioned':
      return '#0060ff';
    case 'hold':
      return '#800080';
    case 'optionSolved':
      return '#FFFF00';
    case 'lano':
      return '#116c11';
    case 'Booked':
      return '#ADD8E6';
    default:
      return '#606060';
  }
};

const TalentOrdersList=({ authToken })=> {
    const navigate=useNavigate();
  const [talentIds, setTalentIds] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [groupedBookings, setGroupedBookings] = useState({});
  const today = new Date();
  var yesterday = new Date((new Date()).valueOf() - 1000*60*60*24);
  const todayAMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
  const todayAWeekAgo = new Date(today.setDate(today.getDate() - 7)); // Set to a week ago
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (bookingId) => {
    setExpandedItems(prev => ({
      ...prev,
      [bookingId]: !prev[bookingId]
    }));
  };

  const day = Timestamp.fromDate(todayAWeekAgo)
useEffect(() => {
    const fetchUserTalents = async () => {
      const userDoc = await getDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", localStorage.getItem("userId")));
      const userRole = userDoc.data().role; // assuming role is stored as "role" in UserData
      const fetchedTalentIds = userRole === 'superadmin' ? [] : userDoc.data().talentIds; // if superadmin, set to empty array
      setTalentIds(fetchedTalentIds);

      const talentDocs = await getDocs(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"));
      let talents = talentDocs.docs.map(doc => ({ id: doc.id, name: doc.data().Name, email: doc.data().E_Mail, rate: doc.data().Tagessatz, lanoid: doc.data().Lano_ID, Funktion: doc.data().Funktion }))
        .filter(talent => userRole === 'superadmin' ? true : fetchedTalentIds.includes(talent.id)); // if superadmin, include all talents
        talents = talents.sort((a, b) => a.name.localeCompare(b.name));
      setTalentData(talents);

      // Fetch bookings after setting talents
      fetchBookingsForTalents(userRole === 'superadmin' ? talents.map(talent => talent.id) : fetchedTalentIds);
    };


    const fetchBookingsForTalents = async (ids) => {
      const allBookings = [];
      const today = new Date();
      const todayAWeekAgo = new Date(today.setDate(today.getDate() - 3)); // Set to a week ago
      const day = Timestamp.fromDate(todayAWeekAgo); // Use this for filtering bookings

      for (const id of ids) {
        const bookingsQuery = query(
          collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
          where("talentId", "==", id),
          where("start_date", ">=", day)
        );

        const bookingsSnapshot = await getDocs(bookingsQuery);
        let fetchedBookings = bookingsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        // Fetch all company names concurrently
        const companyNames = await Promise.all(fetchedBookings.map(booking => fetchCompany(booking.companyID)));

        // Assign the company name to each booking
        fetchedBookings.forEach((booking, index) => {
          booking.companyName = companyNames[index];
        });

        // Sort bookings in descending order by start_date


        allBookings.push(...fetchedBookings);
      }

      setBookings(allBookings);
      setGroupedBookings(groupBookings(allBookings));
    }

    fetchUserTalents();

  }, []);

  const groupBookings = (bookingsList) => {
    const grouped = {};

    // Group bookings by their group ID
    bookingsList.forEach((booking) => {
      if (!grouped[booking.group]) {
        grouped[booking.group] = [];
      }
      grouped[booking.group].push(booking);
    });

    // Sort bookings in each group and mark the last one
    Object.keys(grouped).forEach((groupId) => {
      const sortedBookings = grouped[groupId].sort((a, b) => a.start_date.seconds - b.start_date.seconds);
      sortedBookings[sortedBookings.length - 1].isLast = true; // Mark the last booking
    });

    return grouped;
  };


  const fetchCompany = async (companyID) => {
    if (!companyID) {
      console.error("Undefined companyID found!");
      return 'Unknown Company';
    }
    const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID);
    const company = await getDoc(companyDoc);

    if (company.exists) {
      return company.data().name; // assuming the talent document has a 'name' field
    } else {
      return 'deleted company';
    }
  }

  const handleBookingStatusChange = async (filteredBooking, bookingId, talent, option, action) => {
    try {
      let status;
      if (action === 'accept') {
        if(filteredBooking.isdummy){
          status = 'talentOnHold'
        } else {   
          status = option === 'booking' ? 'confirmed' : 'optioned';
        }
      } else if (action === 'reject') {
        status = 'declined request';
        const newBooking = {
          ...filteredBooking,
          status: 'new order',
          talentId:'',
          group:null,
          groupColor:'',
          statusUpdatedAt: Timestamp.now(),
        };
        delete newBooking.id;
        
        await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"), newBooking);
      }

      const bookingDocRef = doc(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
      await updateDoc(bookingDocRef, { 
        status: status, 
        statusUpdatedAt: Timestamp.now(), 
      });

      // Update local state
      const updatedBookings = bookings.map(b => 
        b.id === bookingId ? { ...b, status: status } : b
      );
      setBookings(updatedBookings);
      setGroupedBookings(groupBookings(updatedBookings));

      alert('Updated successfully!');
      window.location.reload();
    } catch (error) {
      console.error("Error updating booking status:", error);
    }
  };

return(

<div>
<section>
      <div className="content-wrapper">
        <div className='h1-container' style={{ justifyContent: 'space-between' , display: 'flex' }}>
          <h1>Projects</h1>
          <button className={'big-button'  }  onClick={() => navigate('../my-bookings-page')} >calendar</button>
        </div>
      </div>
      <div>
        {talentData.map(talent => (
        <div key={talent.id}>
          <div className="content-wrapper">
            <h2 style={{ marginTop:'70px',  marginBottom: '50px' }}>{talent.name}- {talent.Funktion}</h2>
          </div>
              <div className='content-wrapper content-wrapper-talent-list'>
                <div className='list-tasks-header desktop-only'>
                  <div style={{ flexBasis: '150px' }}>Client</div>
                  <div style={{ flexBasis: '150px' }}>Status</div>
                  <div style={{ flexBasis: '150px' }}>Date</div>
                  <div style={{ flexBasis: '150px' }}>Start-End</div>
                  <div style={{ flexBasis: '150px' }}>KPI</div>
                  <div style={{ flexBasis: '150px' }}>Set</div>
                  <div style={{ flexBasis: '150px' }}>Rate</div>
                </div>
                  {Object.entries(groupedBookings).map(([groupId, groupedBooking]) => {
                    // Filter bookings for the current talent only
                    const bookingsForTalent = groupedBooking.filter(booking => booking.talentId === talent.id);
                    


                    return bookingsForTalent.map((filteredBooking) => (
                
                      <div 
                        className='tlist-mobile-container'
                        key={filteredBooking.id}
                      >
                        <div 
                          className='tlist-preview-row'
                          onClick={() => toggleExpand(filteredBooking.id)}
                          style={{ 
                            borderLeft: `4px solid ${getStatusColor(filteredBooking.status)}`,
                            backgroundColor: filteredBooking.status === 'requested' ? '#fff8ec' : '#fff'
                          }}
                        >
                          <div className='tlist-preview-date'>
                            {new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}
                          </div>
                          <div className='tlist-preview-client'>
                            {filteredBooking.client.label}
                          </div>
                          <div className='tlist-preview-arrow'>
                            {expandedItems[filteredBooking.id] ? '↑' : '↓'}
                          </div>
                        </div>

                        {expandedItems[filteredBooking.id] && (
                          <div className='tlist-expanded-content'>
                            <div className='tlist-detail-row'>
                              <div className='tlist-detail-heading'>Status</div>
                              <div>{filteredBooking.status}</div>
                            </div>
                            <div className='tlist-detail-row'>
                              <div className='tlist-detail-heading'>Start-End</div>
                              <div>{filteredBooking.startTime}- {filteredBooking.endTime}</div>
                            </div>
                            <div className='tlist-detail-row'>
                              <div className='tlist-detail-heading'>KPI</div>
                              <div>{filteredBooking.kpi}</div>
                            </div>
                            <div className='tlist-detail-row'>
                              <div className='tlist-detail-heading'>Set</div>
                              <div>{filteredBooking.set}</div>
                            </div>
                            <div className='tlist-detail-row'>
                              <div className='tlist-detail-heading'>Rate</div>
                              <div>{talent.rate}</div>
                            </div>

                            {filteredBooking.status === 'requested' && (
                              <div className='tlist-actions'>
                                {filteredBooking.option === 'option'
                                  ? <button className='button' onClick={() => handleBookingStatusChange(filteredBooking, filteredBooking.id, talent, filteredBooking.option, 'accept')}>Accept First Option</button>
                                  : <button className='button' onClick={() => handleBookingStatusChange(filteredBooking, filteredBooking.id, talent, filteredBooking.option, 'accept')}>Accept Confirmation</button>
                                }
                                <button className='button' onClick={() => handleBookingStatusChange(filteredBooking, filteredBooking.id, talent, filteredBooking.option, 'reject')}>Deny</button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                ));
              })}
            </div>
          </div>
        ))}
      </div>
  </section>
</div>



);  


}

  export default TalentOrdersList;
  