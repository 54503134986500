import React, { useState, createContext, useContext, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
  Timestamp,
  addDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "./../../firebaseConfig"; // Update the path as per your project structure
import moment from "moment";
import "./../../App.css";
import Select from "react-select";
import { customStyles, selector } from "./../../helpers/StyleUtils";
import late from "./../../images/late.svg";
import early from "./../../images/early.svg";
import notesicon from "./../../images/notes.svg";
import plus from "./../../images/add.svg";
import snotes from "./../../images/setcast_note_v1-blau.svg";
import shiftsAvailability from "./../../images/availability.svg";
import BookingModal from './BookingModal'; // Import the modal component
import suspensionImage from './../../images/music.png';
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";

// Calendar Context
const CalendarContext = createContext();

const CalendarProvider = ({ children }) => {
  // Calculate the start of the week (Monday)
  const startOfWeek = moment.utc().add(2, "hours").startOf("isoWeek");

  const [dateRange, setDateRange] = useState({
    start: startOfWeek.toDate(),
    end: moment(startOfWeek).add(6, "days").toDate(), // Add 6 days to get to Sunday
  });

  const updateDateRange = (newRange) => {
    setDateRange(newRange);
  };
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const clearSelectedClient = () => setSelectedClient(null);

  return (
    <CalendarContext.Provider
      value={{
        dateRange,
        updateDateRange,
        selectedClient,
        setSelectedClient,
        clearSelectedClient,
        selectedStatus,
        setSelectedStatus,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

const useCalendar = () => useContext(CalendarContext);

const DateNavigator = () => {
  const { dateRange, updateDateRange, clearSelectedClient } = useCalendar();

  const navigate = (days) => {
    let newStart = moment(dateRange.start).add(days, "days");
    let newEnd = moment(newStart).add(6, "days");

    updateDateRange({ start: newStart.toDate(), end: newEnd.toDate() });
    clearSelectedClient();
  };

  const generateWeekGrid = () => {
    let grid = [];
    let currentDate = new Date(dateRange.start);

    for (let i = 0; i < 7; i++) {
      grid.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return grid;
  };

  const weekGrid = generateWeekGrid();

  return (
    <div
      style={{
        position: "sticky",
        top: "5px",
        padding: "20px 0",
        backgroundColor: "#ffffff",
        zIndex: "5",
      }}
    >
      <div className="tlistcal-week-grid">
        <div style={{ flexBasis: "400px" }}>
          <button className="button" onClick={() => navigate(-7)}>
            Prev
          </button>
          <button className="button" onClick={() => navigate(7)}>
            Next{" "}
          </button>
        </div>

        <div style={{ width: "150px" }}></div>

        {weekGrid.map((day, index) => (
          <div key={index} className="tlistcal-day-column">
            {moment(day).format('ddd MMM DD')}
          </div>
        ))}
      </div>
    </div>
  );
};

// Talent Component
const Talent = ({ talent }) => {
  const { dateRange } = useCalendar();
  const [showDetail, setshowDetail] = useState(false);
  const [tooltipStates, setTooltipStates] = useState({});
  const [availabilities, setAvailabilities] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNotesOpen, setIsModalNotesOpen] = useState(false);
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [notesForDay, setNotesForDay] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [newAvailability, setNewAvailability] = useState({ startDate: '', endDate: '' });
  const [setCastTalentAvailabillity, setSetCastTalentAvailabillity] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTalentId, setSelectedTalentId] = useState('');
  const [selectedTalentPosition, setSelectedTalentPosition] = useState('');
  const [selectedTalentSet, setSelectedTalentSet] = useState('');
  const [selectedTalentEmail, setSelectedTalentEmail] = useState('')
  const [availabilityStatus, setAvailabilityStatus] = useState('available'); // Add state for availability status
  const [selectedTalentName, setSelectedTalentName] = useState('');
  const [selectedTalentRate, setSelectedTalentRate] = useState('');
  const [notes, setNotes] = useState([]); // Store notes for the week
  const [awaitings, setAwaitings] = useState([]);
  const [isNotizModalOpen, setIsNotizModalOpen] = useState(false);
  const [notizSetcast, setNotizSetcast] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedNotiz, setEditedNotiz] = useState('');
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);

  useEffect(() => {
    const fetchAvailabilityAndVacation = async (id) => {
      // Fetch availability
      const availabilityQuery = query(
        collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability"),
        where("talentId", "==", id)
      );
      const availabilitySnap = await getDocs(availabilityQuery);
      const fetchedAvailabilities = availabilitySnap.docs.map((doc) => {
        const data = doc.data();
        return {
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          addedAt: data.addedAt?.toDate() || new Date(0), // Handle cases where addedAt might not exist
        };
      });
      setAvailabilities((prev) => [...prev, ...fetchedAvailabilities]);

      // Fetch vacations
      const vacationQuery = query(
        collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations"),
        where("talentId", "==", id)
      );
      const vacationSnap = await getDocs(vacationQuery);
      const fetchedVacations = vacationSnap.docs.map((doc) => {
        const data = doc.data();
        return {
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          addedAt: data.addedAt?.toDate() || new Date(0), // Handle cases where addedAt might not exist
        };
      });
      setVacations((prev) => [...prev, ...fetchedVacations]);

      // Fetch awaiting status
      const awaitingQuery = query(
        collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAwaiting"),
        where("talentId", "==", id)
      );
      const awaitingSnap = await getDocs(awaitingQuery);
      const fetchedAwaitings = awaitingSnap.docs.map((doc) => {
        const data = doc.data();
        return {
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          addedAt: data.addedAt?.toDate() || new Date(0),
        };
      });
      setAwaitings((prev) => [...prev, ...fetchedAwaitings]);
    };

    // Fetch for main talent
    fetchAvailabilityAndVacation(talent.id);

    if (Array.isArray(talent.relatedTalent)) {
      talent.relatedTalent.forEach((relatedTalentId) => {
        fetchAvailabilityAndVacation(relatedTalentId);
      });
    }
  }, [talent.id, talent.relatedTalent]);

  useEffect(() => {
    const fetchNotesForWeek = async () => {
      const formattedStart = moment(dateRange.start).format('YYYY-MM-DD');
      const formattedEnd = moment(dateRange.end).format('YYYY-MM-DD');
      const notesQuery = query(
        collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/SetCastNotes"),
        where("talentId", "==", talent.id),
        where("date", ">=", formattedStart),
        where("date", "<=", formattedEnd)
      );

      try {
        const notesSnap = await getDocs(notesQuery);
        const notesData = notesSnap.docs.map((doc) => ({
          date: doc.data().date,
          ...doc.data(),
        }));
        setNotes(notesData);
      } catch (error) {
        console.error("Error fetching notes: ", error);
      }
    };

    fetchNotesForWeek();
  }, [talent.id, dateRange.start, dateRange.end]);

  const isDateWithinRange = (date, ranges) => {
    // Group entries by their date ranges
    const entriesByDateRange = ranges.reduce((acc, range) => {
      // Convert the date parameter to start of day for comparison
      const checkDate = moment(date).startOf('day');
      const rangeStart = moment(range.startDate).startOf('day');
      const rangeEnd = moment(range.endDate).startOf('day');

      // Check if the date falls within the range (inclusive)
      if (checkDate.isSameOrAfter(rangeStart) && checkDate.isSameOrBefore(rangeEnd)) {
        acc.push(range);
      }
      return acc;
    }, []);

    // If we found any matching ranges, return the latest one based on addedAt
    if (entriesByDateRange.length > 0) {
      return entriesByDateRange.reduce((latest, current) => {
        return !latest || current.addedAt > latest.addedAt ? current : latest;
      }, null);
    }

    return null;
  };

  const getDayStyle = (day) => {
    const availableEntry = isDateWithinRange(day, availabilities);
    const vacationEntry = isDateWithinRange(day, vacations);
    const awaitingEntry = isDateWithinRange(day, awaitings);

    // Compare timestamps to get the latest entry
    const entries = [
      availableEntry && { type: 'available', entry: availableEntry },
      vacationEntry && { type: 'vacation', entry: vacationEntry },
      awaitingEntry && { type: 'awaiting', entry: awaitingEntry }
    ].filter(Boolean);

    if (entries.length > 0) {
      const latestEntry = entries.reduce((latest, current) => {
        return !latest || current.entry.addedAt > latest.entry.addedAt ? current : latest;
      }, null);

      switch (latestEntry.type) {
        case 'available':
          return { backgroundColor: "#2ACEF1", opacity: 1, width: '70px' };
        case 'vacation':
          return { backgroundColor: "#F12A5D", opacity: 1, width: '70px' };
        case 'awaiting':
          return { backgroundColor: "#FFA500", opacity: 1, width: '70px' }; // Orange for awaiting
      }
    }
    return { backgroundColor: "white", opacity: 1, width: '70px' };
  };

  const renderNoteIcon = (day) => {
    const hasNotes = notes.some((note) => note.date === moment(day).format('YYYY-MM-DD'));

    const iconStyle = hasNotes
      ? { backgroundColor: "#F12A5D", padding: "1px" }
      : {};

    return (
      <div className="early-shifts-img-cont">
        <img
          src={notesicon}
          className="early-late-icon"
          style={iconStyle}
          onClick={() => openNotesModal(day)}
        />
      </div>
    );
  };

  const getBorderRadius = (set) => {
    switch (set) {
      case "On Model":
        return "10px";
      case "on Produkt":
        return "0px";
      default:
        return "5px"; // Default border radius
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "confirmed":
        return "#15a326";
      case "new order":
        return "#ffffff";
      case "requested":
        return "#FF8806";
      case "talentaccepted":
        return "#3de651";
      case "declined request":
        return "#ff3c00";
      case "optioned":
        return "#0060ff";
      case "optionSolved":
        return "#FFFF00";
      case "hold":
        return "#800080";
      case "lano":
        return "#116c11";
      case "Booked":
        return "#ADD8E6";
      default:
        return "#606060";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".toltip")) {
        setTooltipStates({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleTooltip = (bookingId) => {
    setTooltipStates((prevStates) => ({
      ...prevStates,
      [bookingId]: !prevStates[bookingId],
    }));
  };

  useEffect(() => {
    const fetchBookingsForTalent = async () => {
      const bookingsCollection = collection(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
      );

      // Fetch related talents first
      const talentDoc = await getDoc(
        doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          talent.id
        )
      );
      const talentData = talentDoc.data();
      const relatedTalents = talentData.relatedTalent
        ? talentData.relatedTalent
        : "";

      const talentIds = [talent.id, ...relatedTalents];

      const queries = talentIds.map((talentId) =>
        query(
          bookingsCollection,
          where("talentId", "==", talentId),
          where("start_date", ">=", dateRange.start)
        )
      );

      const bookingSnaps = await Promise.all(queries.map((q) => getDocs(q)));
      const allBookings = bookingSnaps.flatMap((snap) =>
        snap.docs.map((doc) => {
          const data = doc.data();
          data.start_date = data.start_date.toDate(); 
          return { id: doc.id, ...data };
        })
      );

      const filteredBookings = allBookings.filter(
        (booking) => booking.status !== "deleted"
      );

      setBookings(filteredBookings);
    };

    fetchBookingsForTalent();
  }, [talent.id, dateRange.start, dateRange.end]);

  const isEarlyShift = (booking) => {
    const [hours, minutes] = booking.startTime.split(":").map(Number);
    return hours < 12 || (hours === 12 && minutes === 0);
  };

  const generateWeekGrid = () => {
    let grid = [];
    let currentDate = new Date(dateRange.start);

    for (let i = 0; i < 7; i++) {
      grid.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return grid;
  };

  const weekGrid = generateWeekGrid();

  const openModal = (day) => {
    setSelectedDay(day);
    setNewAvailability({ startDate: moment(day).format('YYYY-MM-DD'), endDate: '' });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewAvailability({ startDate: '', endDate: '' });
  };

  const handleAvailabilityChange = (e) => {
    const { name, value } = e.target;
    setNewAvailability((prev) => ({ ...prev, [name]: value }));

  };

  const saveAvailability = async () => {
    const startDate = new Date(newAvailability.startDate);
    const endDate = new Date(newAvailability.endDate);

    if (startDate) {
      const now = new Date();
      const newEntryData = {
        talentId: talent.id,
        startDate: Timestamp.fromDate(startDate),
        endDate: Timestamp.fromDate(startDate),
        addedAt: Timestamp.fromDate(now),
      };
      const newDatas = {
        talentId: talent.id,
        startDate: startDate,
        endDate: startDate,
        addedAt: now,
      };

      // Modified to handle three states
      switch (availabilityStatus) {
        case 'available':
          await addDoc(
            collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability"),
            newEntryData
          );
          setAvailabilities((prev) => [...prev, { ...newDatas }]);
          break;
        case 'not-available':
          await addDoc(
            collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations"),
            newEntryData
          );
          setVacations((prev) => [...prev, { ...newDatas }]);
          break;
        case 'awaiting':
          await addDoc(
            collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAwaiting"),
            newEntryData
          );
          setAwaitings((prev) => [...prev, { ...newDatas }]);
          break;
      }

      closeModal();
    }
  };

  const openNotesModal = (day) => {
    setSelectedDay(day);
    setIsModalNotesOpen(true);
    const formattedDay = moment(day).format('YYYY-MM-DD');
    const notesForSelectedDay = notes.filter(note => note.date === formattedDay);
    setNotesForDay(notesForSelectedDay);
  };

  const closeNotesModal = () => {
    setIsModalNotesOpen(false);
    setNotesForDay([]);
    setNewNote("");
  };

  const openBookingModal = (day, talentId, talentfunktion, talentName, talentSet, talentEmail, talentRate) => {
    setSelectedDate(day);
    setSelectedTalentId(talentId);
    setSelectedTalentPosition(talentfunktion);
    setSelectedTalentName(talentName);
    setSelectedTalentSet(talentSet);
    setSelectedTalentEmail(talentEmail);
    setSelectedTalentRate (talentRate)
  };

  useEffect(() => {
    if (selectedDate && selectedTalentId && selectedTalentPosition && selectedTalentName &&selectedTalentSet && selectedTalentEmail, selectedTalentRate) {
      console.log(selectedTalentSet);
      setIsModalBookingOpen(true);
    }
  }, [selectedDate, selectedTalentId, selectedTalentPosition, selectedTalentName, selectedTalentSet, selectedTalentEmail, selectedTalentRate]);

  const closeBookingModal = () => {
    setIsModalBookingOpen(false);
    setSelectedDate('');
    setSelectedTalentId('');
    setSelectedTalentPosition('');
    setSelectedTalentName('');
    setSelectedTalentSet('');
    setSelectedTalentEmail('');
    setSelectedTalentRate ('')
  };

  const handleNoteChange = (e) => {
    setNewNote(e.target.value);
  };

  const saveNote = async () => {
    const formattedDay = moment(selectedDay).format('YYYY-MM-DD');
    const noteData = {
        notes: newNote,
        talentId: talent.id,
        date: formattedDay,
        time: Timestamp.fromDate(new Date()),
        user: localStorage.getItem("userEmail"),
    };
    try {
        await addDoc(
            collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/SetCastNotes"),
            noteData
        );

        // Update the notes state with the new note
        setNotes((prevNotes) => [...prevNotes, noteData]);

        // Also update notesForDay to reflect in the modal
        setNotesForDay((prevNotes) => [...prevNotes, noteData]);

        setNewNote(""); // Clear the text area after saving
    } catch (error) {
        console.error("Error saving note: ", error);
    }
};

  const openNotizModal = async () => {
    setIsLoadingNotes(true);
    try {
      const talentDoc = await getDoc(
        doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          talent.id
        )
      );
      const talentData = talentDoc.data();
      const currentNotiz = talentData.Notiz_Setcast || '';
      setNotizSetcast(currentNotiz);
      setEditedNotiz(currentNotiz);
      setIsNotizModalOpen(true);
    } catch (error) {
      console.error("Error fetching Notiz_Setcast:", error);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  const closeNotizModal = () => {
    setIsNotizModalOpen(false);
  };

  const saveNotizChanges = async () => {
    try {
      const userEmail = localStorage.getItem("userEmail");
      const timestamp = new Date().toISOString();
      const updatedNotiz = `${editedNotiz}\n\n[Edited by ${userEmail} on ${timestamp}]`;

      await updateDoc(
        doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          talent.id
        ),
        {
          Notiz_Setcast: updatedNotiz
        }
      );

      setNotizSetcast(updatedNotiz);
      setIsEditing(false);
      // Show success message or handle UI feedback
    } catch (error) {
      console.error("Error saving notes:", error);
      // Show error message or handle error state
    }
  };

  const addNewNote = async () => {
    try {
      const userEmail = localStorage.getItem("userEmail");
      const timestamp = new Date().toISOString();
      const newNoteText = `${notizSetcast}\n\n${editedNotiz}\n[Added by ${userEmail} on ${timestamp}]`;

      await updateDoc(
        doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          talent.id
        ),
        {
          Notiz_Setcast: newNoteText
        }
      );

      setNotizSetcast(newNoteText);
      setEditedNotiz('');
      // Show success message or handle UI feedback
    } catch (error) {
      console.error("Error adding new note:", error);
      // Show error message or handle error state
    }
  };

  return (
    <div className="tlistcal-week-grid" style={{ display: "flex", justifyContent: "space-between", }}>
   
  
      <div style={{ flexBasis: "400px", fontWeight: "600", margin: "auto" ,justifyContent: "space-between", minHeight: "85px" }}>
      {talent.Name}{" "} 
        <div style={{ fontWeight: "400", fontSize: "12px" }}>
          {talent.Funktion} | {talent.Bereich}

        </div>
        <div style={{ fontWeight: "400", fontSize: "12px" }}>
 
          {talent.E_Mail}
        </div>
        <div style={{ fontWeight: "400", fontSize: "12px" }}>
 
          {talent.Telefon}
        </div>
        <div style={{ fontWeight: "400", fontSize: "12px" }}>
        {talent.Tagessatz}€/day
    
        </div>

      
       
      </div>

      <div style={{ 
        display: "flex", 
        flexDirection: "column",
        marginTop: "0px", 
        height: "25px", 
        backgroundColor: "#FDFD96", 
        minWidth: "50px", 
        border: "1px solid #444", 
        marginBottom: "10px", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: "5px", 
        textAlign: "center", 
        position: "relative",
        cursor: isLoadingNotes ? "wait" : "pointer",
        transition: "all 0.2s ease",
        opacity: isLoadingNotes ? 0.7 : 1,
        fontSize: "16px",
      }} 
  
      onClick={openNotizModal}>
        {isLoadingNotes ? (
          <ClipLoader 
            color="#000000" 
            size={16} 
            loading={true}
          />
        ) : (
          'notes'
        )}
      </div>

      {weekGrid.map((day, index) => (
        <div
          key={index}
          className="tlistcal-item tlistcal-c-column"
        >
          <div style={{ display: 'flex', justifyContent: 'start', border: 'solid 1px', maxHeight: '25px' }}>
            <div style={getDayStyle(day)}></div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {renderNoteIcon(day)}

              <div className="early-shifts-img-cont">
                <img src={shiftsAvailability} className="early-late-icon" onClick={() => openModal(day)} />
              </div>
              <div className="early-shifts-img-cont"> 
                <img src={plus} className="early-late-icon" onClick={() => openBookingModal(day, talent.id, talent.Funktion, talent.Name, talent.Bereich, talent.E_Mail, talent.Tagessatz)} />
              </div>
            </div>
          </div>

          <div className="early-shifts">
            <div style={{ minHeight: "20px" }}>
              {bookings
                .filter(
                  (booking) =>
                    new Date(booking.start_date).toDateString() ===
                      day.toDateString() && isEarlyShift(booking)
                )
                .map((filteredBooking) => (
                  <div
                    key={filteredBooking.id}
                    className="tlistcal-booking"
                    style={{
                      borderRadius: getBorderRadius(filteredBooking.set),
                    }}
                    onClick={() => toggleTooltip(filteredBooking.id)}
                  >
                    <div style={{
                      height: 15, width: "100%", fontSize: 12, overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                      <div
                        style={{
                          height: "5px",
                          width: "5px",
                          padding: "7px",
                          backgroundColor: getStatus(filteredBooking.status),
                          borderRadius: "50%",
                          display: "inline-block",
                          float: "right",
                        }}
                      />
               {filteredBooking?.client?.label || ' '}

                    </div>

                    {tooltipStates[filteredBooking.id] && (
                      <div
                        className="toltip"
                        style={{
                          position: "absolute",
                          width: "600px",
                          height: "auto",
                          top: "10px",
                          left: "10px",
                          background: "#000000",
                          color: "#ffffff",
                          padding: "10px",
                          zIndex: 9999,
                          cursor: "auto",
                        }}
                        onClick={() => setshowDetail(!showDetail)}
                      >
                        <span
                          style={{
                            position: "relative",
                            fontWeight: "600",
                            color: "#ffffff",
                            whiteSpace: "pre-line",
                          }}
                        >
                          <div className="calendar-inner-cell-container">
                            <div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Client `}
                              </div>
                              {` ${filteredBooking.client.label} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Talent `}
                              </div>
                              {` ${filteredBooking.kpi} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Status `}
                              </div>
                              {` ${filteredBooking.status} `}
                            </div>
                            <div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Title `}
                              </div>
                              {` ${filteredBooking.kpi} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Set `}
                              </div>
                              {` ${filteredBooking.set} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Kpi `}
                              </div>
                              {` ${filteredBooking.kpi} `}
                            </div>
                            <div style={{ flexGrow: "1" }}>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Option `}
                              </div>
                              {` ${filteredBooking.option} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Start time `}
                              </div>
                              {` ${moment(filteredBooking.start_date).format(
                                "DD-MM-YYYY"
                              )} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` S.Info `}
                              </div>
                              {` ${filteredBooking.SonstigeInfo} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Proposed by `}
                              </div>
                              {` ${filteredBooking.proposedBy} `}
                            </div>
                            
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="late-shifts" style={{ minHeight: "25px" }}>
            {bookings
              .filter(
                (booking) =>
                  new Date(booking.start_date).toDateString() ===
                    day.toDateString() && !isEarlyShift(booking)
              )
              .map((filteredBooking) => (
                <div
                  key={filteredBooking.id}
                  className="tlistcal-booking"
                  style={{
                    borderRadius: getBorderRadius(filteredBooking.set),
                  }}
                  onClick={() => toggleTooltip(filteredBooking.id)}
                >
                  <div style={{ height: 20, width: "100%" }}>
                    <div
                      style={{
                        height: "7px",
                        width: "7px",
                        padding: "10px",
                        backgroundColor: getStatus(filteredBooking.status),
                        borderRadius: "50%",
                        display: "inline-block",
                        float: "right",
                      }}
                    />
                  </div>

                  {tooltipStates[filteredBooking.id] && (
                    <div
                      className="toltip"
                      style={{
                        position: "absolute",
                        width: "600px",
                        height: "auto",
                        top: "10px",
                        left: "10px",
                        background: "#000000",
                        color: "#ffffff",
                        padding: "10px",
                        zIndex: 9999,
                        cursor: "auto",
                      }}
                      onClick={() => setshowDetail(!showDetail)}
                    >
                      <span
                        style={{
                          position: "relative",
                          fontWeight: "600",
                          color: "#ffffff",
                          whiteSpace: "pre-line",
                        }}
                      >
                        <div className="calendar-inner-cell-container">
                          <div>
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` Client `}
                            </div>
                            {` ${filteredBooking.client.label} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Talent `}
                            </div>
                            {` ${filteredBooking.kpi} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Status `}
                            </div>
                            {` ${filteredBooking.status} `}
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` Title `}
                            </div>
                            {` ${filteredBooking.kpi} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Set `}
                            </div>
                            {` ${filteredBooking.set} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Kpi `}
                            </div>
                            {` ${filteredBooking.kpi} `}
                          </div>
                          <div style={{ flexGrow: "1" }}>
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` Option `}
                            </div>
                            {` ${filteredBooking.option} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Start time `}
                            </div>
                            {` ${moment(filteredBooking.start_date).format(
                              "DD-MM-YYYY"
                            )} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` S.Info `}
                            </div>
                            {` ${filteredBooking.SonstigeInfo} `}
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
          
        </div>
        
      ))}
      <Modal
        isOpen={isModalNotesOpen}
        onRequestClose={closeNotesModal}
        contentLabel="Notes Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
          },
        }}
        
      >
        
        <h2>Notes for {selectedDay && selectedDay.toDateString()}</h2>
  <div>
    {notesForDay.map((note, index) => {
    // Convert Firebase timestamp to Date (assuming note.time is a Firebase timestamp)
    const noteDate = new Date(note.time.seconds * 1000);

    // Format the date as "dd.mm.yy"
    const formattedDate = noteDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    // Format the time as "hh.mm"
    const formattedTime = noteDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

      return (
        <div key={index}>
          <strong>{note.user}:</strong> {note.notes} | {formattedDate}-{formattedTime}
        </div>
      );
    })}
  </div>
        <div className="textarea">
          <textarea
            value={newNote}
            onChange={handleNoteChange}
            placeholder="Add a note"
          />
        </div>
        <button className="button" onClick={saveNote}>Save Note</button>
        <button className="button" onClick={closeNotesModal}>Close</button>
      </Modal>
      <BookingModal
        onClose={closeBookingModal}
        selectedDate={selectedDate}
        selectedTalentId={selectedTalentId}
        selectedFunktion={selectedTalentPosition}
        selectedTalentName={selectedTalentName}
        selectedTalentSet={selectedTalentSet}
        selectedTalentEmail={selectedTalentEmail}
        talentRate={selectedTalentRate}
        isOpen={isModalBookingOpen}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Availability"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div style={{ padding: '20px' }}>
          <h2>Add Availability for {selectedDay && selectedDay.toDateString()}</h2>
          <div style={{ display: "grid" }}>
            <input
              style={{ marginTop: '20px' }}
              type="date"
              name="startDate"
              value={newAvailability.startDate}
              onChange={handleAvailabilityChange}
            />

            <label>
              <input
                type="radio"
                name="status"
                value="available"
                checked={availabilityStatus === 'available'}
                onChange={(e) => setAvailabilityStatus(e.target.value)}
              />
              Available
            </label>
            <label>
              <input
                type="radio"
                name="status"
                value="not-available"
                checked={availabilityStatus === 'not-available'}
                onChange={(e) => setAvailabilityStatus(e.target.value)}
              />
              Not Available
            </label>
            <label>
              <input
                type="radio"
                name="status"
                value="awaiting"
                checked={availabilityStatus === 'awaiting'}
                onChange={(e) => setAvailabilityStatus(e.target.value)}
              />
              Awaiting
            </label>
            <div style={{ display: 'flex', paddingTop: '20px' }}>
              <button className="button" onClick={saveAvailability}>Save Availability</button>
              <button className="button" onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isNotizModalOpen}
        onRequestClose={closeNotizModal}
        contentLabel="Talent Notes"
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "500px",
            maxWidth: "800px",
            maxHeight: "80vh",
            overflow: "auto"
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <h2>Notes for {talent.Name}</h2>
          
          {/* Existing Notes Display */}
          <div style={{ 
            whiteSpace: "pre-wrap",
            marginTop: "10px",
            marginBottom: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#f9f9f9"
          }}>
            {notizSetcast || 'No notes available'}
          </div>

          {/* Add New Note Section */}
          <div style={{ marginTop: "20px" }}>
            <h3>Add New Note</h3>
            <textarea
              value={editedNotiz}
              onChange={(e) => setEditedNotiz(e.target.value)}
              style={{
                width: "100%",
                minHeight: "100px",
                padding: "8px",
                marginBottom: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc"
              }}
              placeholder="Type your new note here..."
            />
            <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
              <button 
                className="button"
                onClick={addNewNote}
                disabled={!editedNotiz.trim()}
              >
                Add Note
              </button>
              <button 
                className="button"
                onClick={() => {
                  setEditedNotiz('');
                  setIsNotizModalOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
 
    </div>
  );
};

// Main Component
const TalentListCalendarmain = () => {
  const { dateRange, selectedClient, setSelectedClient, selectedStatus, setSelectedStatus } = useCalendar();

  // Add loading state
  const [loading, setLoading] = useState(true);

  const [talents, setTalents] = useState([]);

  const [selectedTalents, setSelectedTalents] = useState([]);
  const [clients, setClients] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedSet, setSelectedSet] = useState("");
  const [selectedFunc, setSelectedFunc] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const bookingStatusOptions = [
    { value: 'Booked', label: 'booked / Lano task created' },
    { value: 'confirmed', label: 'confirmed' },
    { value: 'new order', label: 'new order' },
    { value: 'requested', label: 'requested' },
    { value: 'declined request', label: 'declined request' },
    { value: 'optioned', label: 'optioned' },
    { value: 'optionSolved', label: 'option released' },
    { value: 'talentOnHold', label: '1. Option' },

    
    // ... other statuses
  ];
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Combine all your existing fetch calls
        const [talentSnap, locationSnap, clientSnap] = await Promise.all([
          getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data")),
          getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations")),
          getDocs(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"))
        ]);

        const talentData = talentSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        talentData.sort((a, b) =>
          a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
        );
        setTalents(talentData);

        const locationData = locationSnap.docs.map((doc) => doc.data().short);
        setLocations(locationData);

        const ClientData = clientSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(ClientData);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBookingsForTalent = async () => {
      const bookingsCollectionRef = collection(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
      );

      // Assuming dateRange.start and dateRange.end are JavaScript Date objects
      // If they're not, you'll need to convert them to Firestore Timestamps
      const startTimestamp = dateRange.start; // Convert to Firestore Timestamp if necessary
      const endTimestamp = dateRange.end; // Convert to Firestore Timestamp if necessary

      const bookingsQuery = query(
        bookingsCollectionRef,
        where("start_date", ">=", startTimestamp),
        where("start_date", "<=", endTimestamp)
      );

      try {
        const querySnapshot = await getDocs(bookingsQuery);
        const allBookings = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Assuming 'start_date' is stored as a Firestore Timestamp, convert it to a JavaScript Date object
          return { id: doc.id, ...data, start_date: data.start_date.toDate() };
        });

        setBookings(allBookings);
      } catch (error) {
        console.error("Error fetching bookings: ", error);
      }
    };

    fetchBookingsForTalent();
  }, [dateRange.start, dateRange.end]); // Add db to dependency array if it's a state/prop

  const handleSelectChange = (selectedOptions) => {
    setSelectedTalents(selectedOptions || []);
    setSelectedClient(null);
  };

  const handleSetChange = (selectedOptions) => {
    setSelectedSet(selectedOptions);
  };
  const handleFuncChange = (selectedOptions) => {
    setSelectedFunc(selectedOptions);
  };
  const handleLocations = (selectedOptions) => {
    setSelectedLocations(selectedOptions);
  };

  const handleClientSelectChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    if (selectedOption == null) {
      setSelectedTalents([]);
    } else {
      // Filter talents based on the selected client in bookings
      const talentIdsForSelectedClient = bookings
        .filter((booking) => booking.client.label === selectedOption.label)
        .map((booking) => booking.talentId);

      // Show popup if no bookings found and clear the client filter
      if (talentIdsForSelectedClient.length === 0) {
        setShowNoBookingsPopup(true);
        // Clear the client filter
        setSelectedClient(null);
        // Auto-hide popup after 3 seconds
        setTimeout(() => {
          setShowNoBookingsPopup(false);
        }, 3000);
        return; // Exit the function early since we've cleared the filter
      }

      // Now set a state or directly filter talents to pass to the rendering logic
      const filteredTalentsBasedOnClient = talents.filter((talent) =>
        talentIdsForSelectedClient.includes(talent.id)
      );
      const optionsForSelect = filteredTalentsBasedOnClient.map((talent) => ({
        value: talent.id,
        label: talent.Name,
      }));

      setSelectedTalents(optionsForSelect);
    }
  };

  const handleSttatus = (selectedOption) => {
 

    if (selectedOption == null) {
      setSelectedTalents([]);
      setSelectedStatus('');
    } else {
      setSelectedStatus(selectedOption);
      // Filter talents based on the selected client in bookings
      const talentIdsForSelectedClient = bookings
        .filter((booking) => booking.status === selectedOption.value) // Ensure this matches how your data is structured
        .map((booking) => booking.talentId);

      // Now set a state or directly filter talents to pass to the rendering logic
      const filteredTalentsBasedOnClient = talents.filter((talent) =>
        talentIdsForSelectedClient.includes(talent.id)
      );
      const optionsForSelect = filteredTalentsBasedOnClient.map((talent) => ({
        value: talent.id,
        label: talent.Name, // Adjust if your talent object uses a different key for the name
      }));

      // Use this filtered list to update your UI
      setSelectedTalents(optionsForSelect);
      console.log(optionsForSelect);
    }
  };


  const selectedClientValue = selectedClient
    ? { value: selectedClient.name, label: selectedClient.value }
    : null;

  const talentOptions = talents.map((talent) => ({
    value: talent.id,
    label: talent.Name + '-' +talent.Funktion + '-' + talent.Bereich,
  }));
  const talentsetOptions = Array.from(
    new Set(talents.map((talent) => talent.Bereich))
  ).map((uniqueBereich) => ({
    value: uniqueBereich,
    label: uniqueBereich,
  }));
  const talentFuncOptions = Array.from(
    new Set(talents.map((talent) => talent.Funktion))
  ).map((uniqueBereich) => ({
    value: uniqueBereich,
    label: uniqueBereich,
  }));
  const locationOptions = locations
    .map((location) => ({
      value: location,
      label: location,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Filter the talents to display based on selection
  const filteredTalentss =
    selectedTalents.length > 0
      ? talents.filter((talent) =>
          selectedTalents.some((selected) => selected.value === talent.id)
        )
      : talents; // Show all talents if none is selected

  // Filter talents based on selected talents and bookings with selected clients
  const filteredTalents = talents.filter((talent) => {
    const isSelectedTalent =
      selectedTalents.length === 0 ||
      selectedTalents.some((selected) => selected.value === talent.id);

    // Find bookings for the current talent
    const talentBookings = bookings.filter(
      (booking) => booking.talentId === talent.id
    );

    if (!selectedClient) {
      return isSelectedTalent;
    }

    // Check if any of the talent's bookings match the selected client
    const hasBookingWithSelectedClient = bookings.filter((booking, index) => {
      console.log(`Current booking at index ${index}:`, booking);
      if (booking.client) {
        if (booking.client.value) {
          return selectedClient && selectedClient.value === booking.client.value;
        } else {
          console.warn(`Booking at index ${index} has a client but no value:`, booking.client);
        }
      } else {
        console.warn(`Booking at index ${index} has no client:`, booking);
      }
      return false; // Exclude bookings without a valid client or client.value
    });
    
    
    
    const hasbookingwithselectedStatus = bookings.filter((booking, index) => {
      try {
        // Check if booking, booking.status, and selectedStatus are defined and not null
        if (booking && booking.status && selectedStatus && selectedStatus.value) {
          return selectedStatus.value === booking.status;
        } else {
          console.warn(`Skipping booking at index ${index} due to missing status or selectedStatus:`, {
            bookingStatus: booking ? booking.status : null,
            selectedStatus: selectedStatus ? selectedStatus.value : null
          });
        }
      } catch (error) {
        console.error(`Error processing booking at index ${index}:`, error, booking);
      }
      return false; // Exclude this booking if any checks fail
    });
    


    return isSelectedTalent || hasBookingWithSelectedClient ||hasbookingwithselectedStatus ;
  });

   
  

  // Add these new state variables with the other state declarations
  const [filterGoodCompanies, setFilterGoodCompanies] = useState([]);
  const [filterBadCompanies, setFilterBadCompanies] = useState([]);
  const [filterTryoutCompanies, setFilterTryoutCompanies] = useState([]);

  // Add these handler functions with the other handlers
  const handleGoodCompaniesChange = (selectedOptions) => {
    setFilterGoodCompanies(selectedOptions);
  };

  const handleBadCompaniesChange = (selectedOptions) => {
    setFilterBadCompanies(selectedOptions);
  };

  const handleTryoutCompaniesChange = (selectedOptions) => {
    setFilterTryoutCompanies(selectedOptions);
  };

  // Add new state for the popup
  const [showNoBookingsPopup, setShowNoBookingsPopup] = useState(false);

  return (
    <div className="content-wrapper" style={{ marginTop: "80px" }}>
      {loading ? (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px'
        }}>
          <ClipLoader
            color="#2ACEF1"
            size={50}
            loading={loading}
          />
          <p style={{ 
            margin: 0,
            fontSize: '18px',
            color: '#666'
          }}>
            Loading talent data...
          </p>
        </div>
      ) : (
        <>
          <div className="input-container" style={{display:'inline'}}>
            {/* First row of filters */}
            <div style={{display:'flex'}}>
              <Select
                isMulti
                options={talentOptions}
                className="multi-select"
                classNamePrefix="select"
                value={selectedTalents}
                onChange={handleSelectChange}
                placeholder="Select Talents"
                isClearable
                styles={selector}
              />

              <Select
                isClearable
                value={selectedClientValue}
                options={clients.map((client) => ({
                  value: client.name,
                  label: client.name,
                }))}
                className="multi-select"
                classNamePrefix="select"
                onChange={handleClientSelectChange}
                placeholder="Select Client"
                styles={selector}
              />
              <Select
                isClearable
                value={selectedStatus}
                options={bookingStatusOptions}
                className="multi-select"
                classNamePrefix="select"
                onChange={handleSttatus}
                placeholder="Select status"
                styles={selector}
              />
            </div>

            {/* Second row of filters */}
            <div style={{display:'flex'}}>
              <Select
                isClearable
                isMulti
                value={selectedFunc}
                options={talentFuncOptions}
                className="multi-select"
                classNamePrefix="select"
                onChange={handleFuncChange}
                placeholder="Select Function"
                styles={selector}
              />
              <Select
                isClearable
                value={selectedLocations}
                isMulti
                options={locationOptions}
                className="multi-select"
                classNamePrefix="select"
                onChange={handleLocations}
                placeholder="Select locations"
                styles={selector}
              />
              <Select
                isClearable
                value={selectedSet}
                options={talentsetOptions}
                className="multi-select"
                classNamePrefix="select"
                onChange={handleSetChange}
                placeholder="Select set"
                styles={selector}
              />
            </div>

            {/* New third row for performance filters */}
            <div style={{display:'flex'}}>
              <Select
                isMulti
                value={filterGoodCompanies}
                onChange={handleGoodCompaniesChange}
                options={clients.map((client) => ({
                  value: client.name,
                  label: client.name,
                }))}
                className="multi-select"
                classNamePrefix="select"
                isClearable
                placeholder="Good Performance"
                styles={selector}
              />
              <Select
                isMulti
                value={filterBadCompanies}
                onChange={handleBadCompaniesChange}
                options={clients.map((client) => ({
                  value: client.name,
                  label: client.name,
                }))}
                className="multi-select"
                classNamePrefix="select"
                isClearable
                placeholder="Bad Performance"
                styles={selector}
              />
              <Select
                isMulti
                value={filterTryoutCompanies}
                onChange={handleTryoutCompaniesChange}
                options={clients.map((client) => ({
                  value: client.name,
                  label: client.name,
                }))}
                className="multi-select"
                classNamePrefix="select"
                isClearable
                placeholder="Try Out"
                styles={selector}
              />
            </div>
          </div>

          {/* Add the new filters to the filtering chain */}
          <DateNavigator />
          <div style={{ marginTop: "30px" }}>
            {filteredTalentss
              .filter(
                (talent) => !selectedSet || talent.Bereich === selectedSet.value
              )
              .filter(
                (talent) =>
                  selectedFunc &&
                  Array.isArray(selectedFunc) &&
                  selectedFunc.length > 0
                    ? selectedFunc.some(
                        (func) => func.label === talent.Funktion
                      )
                    : true
              )
              .filter((talent) =>
                selectedLocations &&
                Array.isArray(selectedLocations) &&
                selectedLocations.length > 0
                  ? Array.isArray(talent.locations) &&
                    talent.locations.length > 0 &&
                    talent.locations.some((talentLocation) =>
                      selectedLocations.some(
                        (selectedLocation) =>
                          selectedLocation.label === talentLocation.label
                      )
                    )
                  : true
              )
              // Add new performance filters
              .filter((talent) =>
                filterGoodCompanies && Array.isArray(filterGoodCompanies) && filterGoodCompanies.length > 0
                  ? talent.goodPerformanceCompany?.length > 0 &&
                    talent.goodPerformanceCompany.some((talentCompany) =>
                      filterGoodCompanies.some(
                        (selectedCompany) => selectedCompany.label === talentCompany.label
                      )
                    )
                  : true
              )
              .filter((talent) =>
                filterBadCompanies && Array.isArray(filterBadCompanies) && filterBadCompanies.length > 0
                  ? talent.badPerformanceCompany?.length > 0 &&
                    talent.badPerformanceCompany.some((talentCompany) =>
                      filterBadCompanies.some(
                        (selectedCompany) => selectedCompany.label === talentCompany.label
                      )
                    )
                  : true
              )
              .filter((talent) =>
                filterTryoutCompanies && Array.isArray(filterTryoutCompanies) && filterTryoutCompanies.length > 0
                  ? talent.tryOutCompany?.length > 0 &&
                    talent.tryOutCompany.some((talentCompany) =>
                      filterTryoutCompanies.some(
                        (selectedCompany) => selectedCompany.label === talentCompany.label
                      )
                    )
                  : true
              )
              .map((talent) => (
                <Talent key={talent.id} talent={talent} />
              ))}
          </div>

          {/* Add this popup notification */}
          {showNoBookingsPopup && (
            <div 
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                padding: '50px',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                zIndex: 1000,
                border: '1px solid #ccc'
              
              }}
            >
              <p style={{ margin: 0, fontSize: '50px', fontWeight: 'bold' }}>
                No bookings for the selected cliet was found on this week
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const TalentListCalendar = () => {
  return (
    <CalendarProvider>
      <TalentListCalendarmain />
    </CalendarProvider>
  );
};
export default TalentListCalendar;
