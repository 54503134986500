import React, { useState, useEffect } from 'react';
import Navbar from './components/NavBars/Navbar';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Routes, useNavigate } from 'react-router-dom';
import Products from './components/pages/Products';
//import Form from './components/pages/oldForm';
import Contactus from './components/pages/ContactUs';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, getDocs, deleteDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "./firebaseConfig";
import { authenticateAndGetToken } from "./helpers/auth";
import Edit from './components/pages/Edit';
import BookingCalendar from './components/pages/BookingCalendar';
import AddTalent from './components/pages/AddTalent';
import TalentList from './components/pages/TalentList';
import CompanyList from './components/pages/CompanyList';
import AddCompany from './components/pages/AddCompany';
import LanoCreateATask from './components/pages/LanoCreateATask';
import AppNavbar from './components/AppNavbar'
import ProtectedRoute from './helpers/ProtectedRoute'; 
import RoleContext from './helpers/RoleContext'; // import the context
import NoPrivilages from './components/pages/NoPrivilages';
import NewBookingCreationPage from './components/pages/ClientService/NewBookingCreationPage';
import ProposeTalentsBookingDetail from './components/SetCastUser/ProposeTalentsBookingDetail';
import NewBookingsPage from './components/SetCastUser/NewBookingsPage';
import MyBookingsPage from'./components/pages/Talent/MyBookingsPage'
import LoginForm from './LoginForm';
import UserDataForm from './components/pages/UserDataForm';
import SetVacationPage from './components/pages/Talent/SetVacationPage';
import MyInvitations from './components/pages/Talent/MyInvitations';
import ProposedBookingsPage from './components/pages/ClientService/ProposedBookingsPage';
import EditBooking from './components/SetCastUser/EditBooking';
import AllBookings from './components/SetCastUser/AllBookings';
import Profile from './components/pages/Profile';
import ExcelUploadButton from './helpers/ExcelUploadButton';
import UploadHistory from './components/pages/UploadHistory';
import AllBookingsPage from './components/pages/AllBookingsPage';
import ConfirmedBooking from './components/SetCastUser/ConfirmedBooking';
import TalentListPlaniing from './components/pages/ClientService/TalentListPlaniing';
import AllFutureBookingGroups from './components/pages/AllFutureBookingGroups';
import BookingDetailsPage from './components/SetCastUser/BookingDetailsPage';
import TalentOrdersList from './components/pages/Talent/TalentOrdersList';
import TalentsOnHold from './components/SetCastUser/TalentsOnHold';
import CreateDummyOrders from './components/SetCastUser/CreateDummyOrder';
import RequestedBookings from './components/SetCastUser/RequestedBookings';
import TalentListCalendar from './components/pages/talentListCalendar';
import { CalendarProvider } from './helpers/CalendarContext';
import TalentUserCreationForm from './components/TalentUserCreationForm';
import DeletedOrdersPage from './components/SetCastUser/DeletedOrdersPage';
import LanoIds from './components/pages/LanoIds';
import TalentOverview from './components/pages/ClientService/TalentOverview';
import MyCalendar from './components/pages/Talent/MyCalendar';
import HomePage from './components/pages/Talent/HomePage';

import Footer from './components/Footer';
export var authcode;
function App() {
  const [authToken, setAuthToken] = useState('null');
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [x, setX] = useState(1);
  const [code, setCode] = useState('bbb');
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
  console.log(localStorage.getItem("userRole"));




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setIsSubmitted(true);
        getToken(); // Only get token if user is signed in
      } else {
        // User is signed out
        setIsSubmitted(false);
        setAuthToken(null); // Clear the auth token when user is signed out
      }
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);

  const getToken = async () => {
    const token = await authenticateAndGetToken();
    setAuthToken(token);
    // Setup refreshToken to be called for the first time immediately after getting token
    refreshToken();
  };

  // Define refreshToken outside useEffect
  const refreshToken = async () => {
    const newToken = await authenticateAndGetToken();
    setAuthToken(newToken);
    // Refresh the token every 7200 seconds
    setTimeout(refreshToken, 7200 * 1000);
  };

  // Conditionally render based on the isSubmitted and authToken states
  if (isSubmitted) {
    if (authToken) {
      // Return the main app UI when the user is logged in and authToken is available
    } else {
      // Return a loading indicator or some placeholder when authToken is being fetched
    }
  } else {
    // Return the login form when the user is not logged in
    return (
      <Router>
        <LoginForm />
      </Router>
    );
  }

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

 



  if (isSubmitted) {
 //   if (authToken ) {
  if (isSubmitted ) {
      return (
<Router>

  <AppNavbar />
  <RoleContext.Provider value={localStorage.getItem("userRole")}> 
    <Routes>
      <Route path="/np/*" element={<ProtectedRoute allowedRoles={[null]} />}>
        <Route index element={<NoPrivilages />} />
      </Route>

      <Route path="/admin/*" element={<ProtectedRoute allowedRoles={['superadmin', 'setCastEditor']} />}>
      <Route path='propose' element={<ProposeTalentsBookingDetail />} />
     
      <Route path='upload' element={<ExcelUploadButton />} />
      <Route index element={<NewBookingsPage />} />
      <Route path='allnewbookings' element={<NewBookingsPage />} />
        <Route path="addtalent" element={<AddTalent />} />
        <Route path="add-company" element={<AddCompany />} />
        <Route path='company-list' element={<CompanyList />} />
        <Route path="lanotaskcreate" element={<LanoCreateATask authToken={authToken} />} />
        <Route path='products' element={<Products authToken={authToken} />} />
        <Route path='lanoids' element={<LanoIds authToken={authToken} />} />
        <Route path='edit' element={<EditBooking />} />
        <Route path='user-data' element={<UserDataForm />} />
        <Route path='profile' element={<Profile />} />
        <Route path='booking' element={<AllBookings />} />
        <Route path='allbooking' element={<AllBookings />} />
        <Route path='talent-list' element={<TalentList />} />
        <Route path='history' element={<UploadHistory />} />
        <Route path='confirmed' element={<ConfirmedBooking   authToken={authToken} />} />
        <Route path='allgroups' element={<AllFutureBookingGroups />} />
        <Route path='detail' element={<BookingDetailsPage />} />
        <Route path='new'element={<NewBookingCreationPage />} />
        <Route path='talents-hold'element={<TalentsOnHold />} />
        <Route path='create-dummy'element={<CreateDummyOrders />} />
        <Route path='requested'element={<RequestedBookings />} />
        <Route path='exporttalents'element={<exportTalents />} />
        
        <Route path='tlistcal'element={<TalentListCalendar />  } />
        <Route path='bulk-create'element={<TalentUserCreationForm />  } />
        <Route path='deleted'element={<DeletedOrdersPage />  } />
      </Route>

      <Route path="/user/*" element={<ProtectedRoute allowedRoles={['editor', 'superadmin', 'setCastEditor']} />}>
        <Route path="addtalent" element={<AddTalent />} />

        <Route path="lanotaskcreate" element={<LanoCreateATask authToken={authToken} />} />
        <Route path='contact-us' element={<Contactus />} />
        <Route index element={<BookingCalendar />} />
        <Route path="add-company" element={<AddCompany />} />
        <Route path='company-list' element={<CompanyList />} />
        <Route path='talent-list' element={<TalentList />} />
        <Route path='profile' element={<Profile />} />
      </Route>

      <Route path="/talent/*" element={<ProtectedRoute allowedRoles={['talent', 'superadmin']} />}>
 

        <Route index element={<HomePage />} />
        <Route path='home' element={<HomePage />} />
        <Route path='my-bookings-page' element={<MyBookingsPage />} />
        <Route path='vacation' element={<SetVacationPage />} />
        <Route path='my-invitations' element={<MyInvitations authToken={authToken}  />} />
        <Route path='profile' element={<Profile />} />
        <Route path='orders' element={<TalentOrdersList />} />

      </Route>


    <Route path="/cs/*" element={<ProtectedRoute allowedRoles={['clientService', 'superadmin',  'planning']} />}>

        <Route index element={<AllBookingsPage />} />
        <Route path='new'element={<NewBookingCreationPage />} />
        <Route path='talent-list' element={<TalentList />} />
        <Route path='allbooking' element={<AllBookingsPage />} />
        <Route path="add-company" element={<AddCompany />} />
        <Route path='company-list' element={<CompanyList />} />
        <Route path='proposed' element={<ProposedBookingsPage authToken={authToken}  />} />
        <Route path='profile' element={<Profile />} />
        <Route path='rate-talent' element={<TalentListPlaniing />} />
        <Route path='allgroups' element={<AllFutureBookingGroups />} />
        <Route path='upload' element={<ExcelUploadButton />} />
        <Route path='tlistcal'element={<TalentListCalendar />  } />
        <Route path='talentoverview'element={<TalentOverview />  } />
    
        <Route path='edit' element={<EditBooking />} />
       
    </Route>
    </Routes>

  </RoleContext.Provider>
<Footer/>
</Router>



      );
    } else if (!authToken) {
  
    // reloads the page after 3 seconds
    return <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
  }
  }

  else    if (!isSubmitted) {
    return (
      <Router>
        <LoginForm />
      </Router>
    );
  }

}

export default App;
