import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

function HomePage() {
  const navigate = useNavigate();
  const [talents, setTalents] = useState([]);

  useEffect(() => {
    const fetchTalents = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const userDoc = await getDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", userId));
        
        if (userDoc.exists()) {
          const talentIds = userDoc.data().talentIds || [];
          const talentPromises = talentIds.map(async (talentId) => {
            const talentDoc = await getDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data", talentId));
            return talentDoc.exists() ? talentDoc.data().Name : null;
          });

          const talentNames = (await Promise.all(talentPromises)).filter(name => name !== null);
          setTalents(talentNames);
        }
      } catch (error) {
        console.error("Error fetching talents:", error);
      }
    };

    fetchTalents();
  }, []);

  return (
    <section>
      <div className="content-wrapper">
        <div className='h1-container'>
          <h1>Welcome to TOBS</h1>
          {talents.length > 0 && (
            <div className="talents-list">
              <p>You currently can view and accept orders for:</p>
              <ul>
                {talents.map((talent, index) => (
                  <li key={index}>{talent}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '20px',
          marginTop: '40px'
        }}>
          {/* My Bookings Card */}
          <div 
            className="home-card" 
            onClick={() => navigate('./orders')}

          >
            <h2>My Bookings</h2>
            <p>View and manage your bookings</p>
          </div>

          {/* My Calendar Card */}
          <div 
            className="home-card" 
            onClick={() => navigate('./my-bookings-page')}

          >
            <h2>My Calendar</h2>
            <p>View your calendar and schedule</p>
          </div>

          {/* Manage Availability Card */}
          <div 
            className="home-card" 
            onClick={() => navigate('./vacation')}
    
          >
            <h2>Manage Your Availability</h2>
            <p>Add and update your availability</p>
          </div>

          {/* My Requests Card */}
          <div 
            className="home-card" 
            onClick={() => navigate('./my-invitations')}
   
          >
            <h2>My Requests</h2>
            <p>Manage requested jobs by accepting or denying them</p>
          </div>
        </div>

        <style>{`
      .home-card {
              background: #fff;
              padding-left: 30px;
              padding-right: 30px;
              padding-bottom: 30px;
              padding-top: 30px;
              border-radius: 8px;
              box-shadow: 0 2px 10px rgba(0,0,0,0.1);
              cursor: pointer;
              transition: transform 0.2s;
              border: 1px solid #eee;
          }'
          .talents-list {
            margin: 20px 0;
          }

          .talents-list p {
            font-size: 18px;
            margin-bottom: 10px;
          }

          .talents-list ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .talents-list li {
            font-size: 18px;
            font-weight: 600;
            color: #333;
            line-height: 1.5;
          }

          .home-card:hover {
            transform: translateY(-5px);
          }
          
          .home-card h2 {
            margin-bottom: 10px;
            color: #333;
          }
          
          .home-card p {
            color: #666;
            margin: 0;
          }
        `}</style>
      </div>
    </section>
  );
}

export default HomePage;
